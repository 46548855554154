<template>

  <v-container id="regular-tables" fluid tag="section">
     
  
  <v-container class="grey lighten-5" id="product__container">
  
    <div class="text-right">
       
       <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>
      </div>
      <v-row>
      
      <v-col cols="6">Duplicate PDF</v-col>
      <v-col cols="3">
        Approved PDF <span>({{get_selected_product_id()}})</span>
      </v-col>
       <v-col cols="3">
      <v-select
            :items="product_ids"
            dense

            v-model="product_id_data"
            label="Product ID"
            @change="product_file = product_id_data"
          >
      </v-select>
       </v-col>
        </v-row>
        <v-row>
      <v-col cols="6">
            <iframe :src="duplicate_file" height="800" width="100%"></iframe>
      </v-col>

      <v-col cols="6">
          <iframe :src="product_file" height="800" width="100%"></iframe>
      </v-col>
        </v-row>   
          
          </v-container>      
 
  </v-container>
</template>
<script>

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
  export default {
    components: {Loading },
    data () {
      return {
        endpoints:{
          "GET_DATA_URL":null,
          "EXPORT_DATA_URL":null
         
        },
        get_data_api_url:null,
        data:[],
        item_key:"",
        totRecords :null,
        perpage:50,
        isLoading: true,
        product_id_data:'',
        fullPage: true,
        product_ids:[],
        page:1,
        headers: [
                {
                    text: 'Name',
                    align: 'start',                   
                    value: 'name',
                    width:"35%",
                     sortable: false,
                    class: "v-data-table-header ",
                },
                
                {
                    text: 'Logins',
                    value: 'lcount',
                     sortable: false,
                   
                    class: "v-data-table-header",
                },
                {
                    text: 'Login Time(HH:MM:SS)',
                    value: 'timediff',
                     sortable: false,
                    width:"15%",
                    class: "v-data-table-header",
                },
                
                

        ],
        showButtons:true,
        search_company_id:null,
        dialog:false,
        added_company_id:null,
        updatedialog:false,
        sender_email:null,
        update_item_id:'',
        company_name:'',
        show_add_delete_buttons:false,
        opendialog:false,
        show_check_box:false,
        editItem:false,
        showexportbutton:false,
        totalloginCount:'',
        totalTime:'',
        companyName:'',
        sdate:'',
        edate:'',
        duplicate_file:'',
        product_file:''
        
        
      }
    },
    mounted() {
      var view = this
      
    },
    methods: {
      get_selected_product_id(){
        let id = ''
        this.product_ids.forEach((ids)=>{
          if(ids.value == this.product_id_data){
            id = ids.text
          }
        })
        return id
      },
      checkIfOperationExistForModule(type){
        return this.$utils.checkIfOperationExistForModule(this, type)
    },
      comparePdf:function(){
         var view = this
         view.isLoading = true
         let crmid=view.$route.params.id

        console.log(this.$route.params.id);

        // console.log(view.$route.params)
         this.$fetch({ requiresAuth: true, operation : 'view', vueScope: view })
        .get(this.$url("COMPARE_PDF")+"/"+crmid)
        .then((response) => {
          
          view.isLoading = false
          if(response.data.duplicate_file && response.data.product_file){
              this.duplicate_file=response.data.duplicate_file
              //this.product_file=response.data.product_file
              this.product_ids=[]
              this.product_file = response.data.product_ids[Object.keys(response.data.product_ids)[0]]
              this.product_id_data = response.data.product_ids[Object.keys(response.data.product_ids)[0]]
                Object.entries(response.data.product_ids).forEach((entry)=>{
                  
                  const [key, value] = entry;
                  console.log(key,value)
                  let temp ={
                    text: key,
                    value: value
                  }
                  this.product_ids.push(temp)
                })

          }

          // if (response.data.statusCode == 200 && this.message1 == null) {
          //   this.$swal.fire({
          //     icon: "success",
          //     title: "Selected record(s) has been deleted",
          //     text: response.data.payload.message,
          //   });
          // }
         
        }).catch((error) => {
           console.error(error);
           view.isLoading = false
           this.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Something went wrong!",
                });
        });
      },  
    },
  watch: {
    '$store.state.Rawdata.current_active_module': function(o) {
 if(o){
    this.$store.dispatch("Rawdata/setDrawer", false)
    this.comparePdf();
 }
 },

 },
  }
</script>
